<template>
  <div class="users">
    <div class="row margin-0">
      <div class="col-md-12" :class="{'pad-15': !user, 'pad-0': user}">
        <div class="parent margin-bottom-15" v-if="visible_form">
          <div class="parent-body pad-0">
            <div class="row margin-0">
              <div class="col pad-0">
                <h4 v-if="!payload.id" class="text-center">
                  Add New Transaction
                </h4>
                <h4 v-if="payload.id" class="text-center">
                  Update Transaction
                </h4>
                <div class="row margin-0 margin-bottom-5">
                  <div class="col-3">
                    <div class="form-group">
                      <label for=""><b>Type</b></label>
                      <select
                        name=""
                        id=""
                        v-model="payload.transaction_type"
                        class="form-control form-control-sm"
                      >
                        <option :value="null">Select Type</option>
                        <option value="DEPOSIT">DEPOSIT</option>
                        <option value="WITHDRAW">WITHDRAW</option>
                      </select>
                    </div>
                    <span
                      class="error"
                      v-if="error && error.transaction_type"
                      >{{ error.transaction_type }}</span
                    >
                  </div>
                  <div class="col-3">
                    <div class="form-group">
                      <label for=""><b>Message</b></label>
                      <input
                        type="text"
                        v-model="payload.message"
                        class="form-control form-control-sm"
                        placeholder="Message"
                      />
                      <span class="error" v-if="error && error.message">{{
                        error.message
                      }}</span>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="form-group">
                      <label for=""><b>Coins</b></label>
                      <input
                        type="number"
                        v-model="payload.coins"
                        class="form-control form-control-sm"
                        placeholder="Coins"
                      />
                      <span class="error" v-if="error && error.coins">{{
                        error.coins
                      }}</span>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="form-group">
                      <label for=""><b>Status</b></label>
                      <select
                        name=""
                        id=""
                        v-model="payload.status"
                        class="form-control form-control-sm"
                      >
                        <option value="SUCCESS">SUCCESS</option>
                        <option value="PENDING">PENDING</option>
                        <option value="FAILED">FAILED</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="form-group">
                      <label for=""><b>Expiry Date</b></label>
                      <input
                        type="datetime-local"
                        v-model="payload.expire_on"
                        class="form-control form-control-sm"
                        placeholder="Expiry Date"
                      />
                      <span class="error" v-if="error && error.expire_on">{{
                        error.expire_on
                      }}</span>
                    </div>
                  </div>
                  <div class="col-12 text-right">
                    <button
                      v-if="!payload.id"
                      @click="newData()"
                      class="btn btn-success btn-sm margin-right-10"
                    >
                      Add New Transaction
                    </button>
                    <button
                      v-if="payload.id"
                      @click="updateData()"
                      class="btn btn-success btn-sm margin-right-10"
                    >
                      Update Transaction
                    </button>
                    <button
                      @click="visible_form = false"
                      class="btn btn-light btn-sm"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="parent">
          <div class="parent-head" v-if="!user">
            <ul>
              <li :class="{ active: tab === 'Courses' }">Transactions</li>
            </ul>
          </div>
          <div class="parent-body">
            <div class="row margin-0 margin-bottom-5">
              <div class="col-2 pad-0">
                <div class="form-group">
                  <label for=""><b>Search Word</b></label>
                  <input
                    type="text"
                    v-model="search_term"
                    placeholder="Search Word"
                    class="form-control form-control-sm"
                  />
                </div>
              </div>
              <div class="col-2">
                <div class="form-group">
                  <label for=""><b>Type</b></label>
                  <select
                    name=""
                    id=""
                    v-model="transaction_type"
                    class="form-control form-control-sm"
                  >
                    <option :value="null">All</option>
                    <option value="DEPOSIT">DEPOSIT</option>
                    <option value="WITHDRAW">WITHDRAW</option>
                  </select>
                </div>
              </div>
              <div class="col-2">
                <div class="form-group">
                  <label for=""><b>Status</b></label>
                  <select
                    name=""
                    id=""
                    v-model="status"
                    class="form-control form-control-sm"
                  >
                    <option :value="null">All</option>
                    <option value="SUCCESS">SUCCESS</option>
                    <option value="PENDING">PENDING</option>
                    <option value="FAILED">FAILED</option>
                  </select>
                </div>
              </div>
              <div class="col-2">
                <div class="form-group">
                  <br />
                  <button
                    @click="getdataList()"
                    class="btn btn-primary btn-sm margin-right-10"
                  >
                    Search
                  </button>
                  <button
                    @click="
                      clearFilter();
                      transaction_type = null;
                      status = null;
                      getdataList();
                    "
                    class="btn btn-light btn-sm"
                  >
                    Clear
                  </button>
                </div>
              </div>
              <div class="col text-right pad-0">
                <div class="form-group">
                  <br />
                  <button
                    v-if="user"
                    @click="visible_form = true;resetPayload();"
                    class="btn btn-primary btn-sm"
                  >
                    + New
                  </button>
                </div>
              </div>
            </div>
            <table class="table sai-table fixed">
              <thead>
                <tr class="t-head">
                  <th>Product</th>
                  <th>Starts On</th>
                  <th>Ends On</th>
                  <th
                    :class="{
                      active_sort:
                        sort_by == 'created_on' || sort_by == '-created_on',
                    }"
                    @click="sortBy('created_on')"
                  >
                    CREATED ON
                    <i
                      :class="{
                        'fa-sort': sort_by != 'created_on',
                        'fa-sort-up': sort_by == 'created_on',
                        'fa-sort-down': sort_by == '-created_on',
                      }"
                      class="fa float-right"
                      style="position: relative; top: 0px"
                    />
                  </th>
                  <th
                    :class="{
                      active_sort: sort_by == 'status' || sort_by == '-status',
                    }"
                    @click="sortBy('status')"
                  >
                    STATUS
                    <i
                      :class="{
                        'fa-sort': sort_by != 'status',
                        'fa-sort-up': sort_by == 'status',
                        'fa-sort-down': sort_by == '-status',
                      }"
                      class="fa float-right"
                      style="position: relative; top: 0px"
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                <template v-if="!loading_list">
                  <template v-for="item in dataList.results" :key="item.id">
                    <tr>
                      <td>{{item.product_details?item.product_details?.title:item.product}}</td>
                      <td>{{$filters.fullDateTime(item.start_on)}}</td>
                      <td>{{$filters.fullDateTime($moment(item.start_on).add(item.days, 'days'))}}</td>
                      <td>{{$filters.fullDateTime(item.created_on)}}</td>
                      <td>
                        <status :status="item.status" />
                        <!-- <span
                          class="fa fa-pencil edit-icon f-right"
                          style="cursor pointer;padding:5px;"
                          @click="dataEdit(item)"
                        /> -->
                      </td>
                    </tr>
                  </template>
                </template>
              </tbody>
              <footer-table
                :data="dataList"
                :loading="loading_list"
                :pageSize="pageSize"
                @action="handleTableAction"
                cols="6"
              ></footer-table>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DataMixin from "../mixins/dataMixin";
export default {
  props: ["user"],
  data() {
    return {
      serviceModule: "transaction",
      transaction_type: null,
      status: null,
      defaultPayload: {},
    };
  },
  mixins: [DataMixin],
  methods: {
    extraParams() {
      let extra = {};
      if (this.transaction_type) {
        extra.transaction_type = this.transaction_type;
      }
      if (this.status) {
        extra.status = this.status;
      }
      if (this.user) {
        extra.user = this.user.id;
      }
      return extra;
    },
    validateData() {
      const that = this;
      that.error = {};
      var proceed = true;
      if (
        that.payload.message === null ||
        that.payload.message === ""
      ) {
        this.error.message = "Message is required";
        proceed = false;
      }
      if (that.payload.coins === null || that.payload.coins === "") {
        this.error.coins = "Coins is required";
        proceed = false;
      }
      if (that.payload.status === null || that.payload.status === "") {
        this.error.status = "Status is required";
        proceed = false;
      }
      if(that.payload.expire_on) {
        that.payload.expire_on = that.$moment(that.payload.expire_on).format('YYYY-MM-DD hh:mm:ss');
      }
      return proceed;
    },
    resetPayload() {
      this.payload = {
        user: this.user.id || null,
        coins: null,
        transaction_type: "DEPOSIT",
        message: null,
        coupon_discount: null,
        data: null,
        expire_on: null,
        status: "SUCCESS",
      };
      this.error = {};
    }
  },
};
</script>
<style lang="stylus" scoped></style>